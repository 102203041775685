<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.paymentsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="paymentMethodsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="paymentMethods"
            :search="searchPaymentMethodString"
            :item-class="addCustomClass"
            class="elevation-1"
            multi-sort>
            <template v-for="header in paymentMethodsHeaders" v-slot:[`item.${header.value}`]="{ item }">
              <td
                v-if="header.value !== 'default' && header.value !== 'visible'"
                :key="header.value"
                @click="columnClick(item, header.value)">
                {{ item[header.value] }}
              </td>
            </template>

            <template v-slot:item.default="{ item }">
              <v-checkbox v-model="item.default" color="success" @click="makePaymentMethodDefault(item)"></v-checkbox>
            </template>

            <template v-slot:item.visible="{ item }">
              <v-checkbox v-model="item.visible" color="success" @click="makePriceVisible(item)"></v-checkbox>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.payments') }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchPaymentMethodString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer />

                <v-dialog v-model="paymentMethodDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(paymentMethodFormTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePaymentMethodEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(savePaymentMethod)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" hidden md="6" sm="12">
                                <v-text-field
                                  v-model="editedPaymentMethodItem.id"
                                  :label="$t('paymentMethodsPage.id')"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="payment_method">
                                  <v-text-field
                                    v-model="editedPaymentMethodItem.name"
                                    autofocus
                                    clearable
                                    counter="200"
                                    :label="$t('paymentMethodsPage.paymentMethod')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="paymentMethodDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedPaymentMethodItem.name }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePaymentMethodDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('paymentMethodsPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        :disabled="!enablePaymentMethodSave"
                        class="primary"
                        text
                        type="submit"
                        @click="deletePaymentMethodItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <td :key="notLastPaymentMethod" @click="columnClick(item, 'actions')" class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="editPaymentMethodItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.edit') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="makePaymentMethodActive(item)">
                          <v-icon v-if="item.active" class="mx-1" small v-bind="attrs"
                            >mdi-toggle-switch-outline</v-icon
                          >
                          <v-icon v-if="!item.active" class="mx-1" small v-bind="attrs"
                            >mdi-toggle-switch-off-outline</v-icon
                          >
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ item.active ? $t('buttons.deactivate') : $t('buttons.activate') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ item.active ? $t('tooltips.deactivate') : $t('tooltips.activate') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)" v-if="notLastPaymentMethod">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { paymentMethodsHeaders } from '@/mixins/data-table-headers';
import { defaultPaymentMethod } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'PaymentMethodPage',
  components: { ButtonSubmit },
  data: () => ({
    searchPaymentMethodString: '',
    paymentMethodDialog: false,
    paymentMethodDialogDelete: false,
    paymentMethods: [],
    paymentMethodFormTitle: 'paymentMethodsPage.newItem',
    paymentMethodFormValid: true,
    editedPaymentMethodItem: {},
    enablePaymentMethodSave: true,
    loading: false,
  }),
  created() {
    this.editedPaymentMethodItem = Object.assign({}, defaultPaymentMethod);
    this.loadAllPaymentMethods();
  },
  computed: {
    paymentMethodsHeaders() {
      return paymentMethodsHeaders(i18n);
    },
    notLastPaymentMethod() {
      return this.paymentMethods?.length > 1 ? true : false;
    },
  },
  methods: {
    async loadAllPaymentMethods() {
      await this.$store.dispatch('paymentMethods/getAllPaymentMethods').then((res) => {
        this.paymentMethods = res.data.map((e) => {
          e.active = parseInt(e.active);
          e.default = parseInt(e.default);
          e.visible = !parseInt(e.hidden); // reverse hidden value because we show visible on front
          return e;
        });
      });
    },

    editPaymentMethodItem(item) {
      this.editedPaymentMethodItem = this.paymentMethods.indexOf(item);
      this.editedPaymentMethodItem = Object.assign({}, item);
      this.paymentMethodFormTitle = item.name;
      this.paymentMethodDialog = true;
    },

    deleteItem(item) {
      this.editedPaymentMethodItem = this.paymentMethods.indexOf(item);
      this.editedPaymentMethodItem = Object.assign({}, item);
      this.paymentMethodDialogDelete = true;
    },

    async savePaymentMethod() {
      this.enablePaymentMethodSave = false;
      this.loading = true;
      let path = 'savePaymentMethod';

      if (this.editedPaymentMethodItem.id) {
        Object.assign(this.editedPaymentMethodItem, { _method: 'POST' });
        path = 'updatePaymentMethod';
      }

      await this.$store
        .dispatch('paymentMethods/' + path, this.editedPaymentMethodItem)
        .then(() => {
          this.loadAllPaymentMethods();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closePaymentMethodEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enablePaymentMethodSave = true;
          this.loading = false;
          this.paymentMethodFormTitle = 'paymentMethodsPage.newItem';
        });
    },

    async deletePaymentMethodItemConfirm() {
      await this.$store
        .dispatch('paymentMethods/deletePaymentMethod', this.editedPaymentMethodItem)
        .then(() => {
          this.loadAllPaymentMethods();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
          this.closePaymentMethodDelete();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closePaymentMethodDelete();
        });
    },

    closePaymentMethodEdit() {
      this.paymentMethodDialog = false;
      this.$nextTick(() => {
        this.editedPaymentMethodItem = Object.assign({}, defaultPaymentMethod);
      });
      this.$refs.form.reset();
      this.paymentMethodFormTitle = 'paymentMethodsPage.newItem';
    },

    closePaymentMethodDelete() {
      this.paymentMethodDialogDelete = false;
      this.$nextTick(() => {
        this.editedPaymentMethodItem = Object.assign({}, defaultPaymentMethod);
      });
    },

    columnClick(item, column) {
      if (column !== 'default') {
        // Perform editItem action for other columns
        this.editPaymentMethodItem(item);
      }
    },

    async makePaymentMethodActive(item) {
      await this.$store
        .dispatch('paymentMethods/changePaymentMethodActiveStatus', item)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
          item.active = !item.active;
        })
        .catch(() => {
          item.active = !item.active;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async makePaymentMethodDefault(item) {
      await this.$store
        .dispatch('paymentMethods/changePaymentMethodDefaultStatus', item)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.defaultStatusChanged'), color: 'green' });
          this.loadAllPaymentMethods();
        })
        .catch(() => {
          item.default = !item.default;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    async makePriceVisible(item) {
      await this.$store
        .dispatch('paymentMethods/changePaymentMethodVisibleStatus', item)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.visibleStatusChanged'), color: 'green' });
          this.loadAllPaymentMethods();
        })
        .catch(() => {
          item.visible = !item.visible;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    addCustomClass(item) {
      return item.active === true || item.active == 1 ? 'active-row' : 'inactive-row';
    },
  },

  watch: {
    paymentMethodDialog(val) {
      val || this.closePaymentMethodEdit();
    },
  },
};
</script>
